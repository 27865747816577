import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';
import { AdminComponent } from './admin.component';

const routes: Routes = [
    {
        'path': '',
        'component': AdminComponent,
        'canActivate': [AuthGuard],
        'children': [
            {
                'path': 'admin',
                'loadChildren': '.\/pages\/dashboard\/dashboard.module#DashboardModule',
            },
            {
                'path': 'ajuda',
                'loadChildren': '.\/pages\/ajuda\/ajuda.module#AjudaModule',
            },
            {
                'path': 'assinatura',
                'loadChildren': '.\/pages\/assinatura\/assinatura.module#AssinaturaModule',
            },
            {
                'path': 'carrinho',
                'loadChildren': '.\/pages\/carrinho\/carrinho.module#CarrinhoModule',
            },
            {
                'path': 'carrinho-garantido',
                'loadChildren': '.\/pages\/carrinho-garantido\/carrinho-garantido.module#CarrinhoGarantidoModule',
            },
            {
                'path': 'carrinho-loja',
                'loadChildren': '.\/pages\/carrinho-loja\/carrinho-loja.module#CarrinhoLojaModule',
            },
            {
                'path': 'carrinho-marketplace',
                'loadChildren': '.\/pages\/carrinho-marketplace\/carrinho-marketplace.module#CarrinhoMarketplaceModule',
            },
            {
                'path': 'comprar',
                'loadChildren': '.\/pages\/comprar\/comprar.module#ComprarModule',
            },
            {
                'path': 'carteira',
                'loadChildren': '.\/pages\/carteira\/carteira.module#CarteiraModule',
            },
            {
                'path': 'credito',
                'loadChildren': '.\/pages\/credito\/credito.module#CreditoModule',
            },
            {
                'path': 'profile',
                'loadChildren': '.\/pages\/profile\/profile.module#ProfileModule',
            },
            {
                'path': 'profile-cha',
                'loadChildren': '.\/pages\/profile-cha\/profile-cha.module#ProfileChaModule',
            },
            {
                'path': 'profile-eventos',
                'loadChildren': '.\/pages\/profile-eventos\/profile-eventos.module#ProfileEventosModule',
            },
            {
                'path': 'eventos',
                'loadChildren': '.\/pages\/eventos\/eventos.module#EventosModule',
            },
            {
                'path': 'extrato',
                'loadChildren': '.\/pages\/extrato\/extrato.module#ExtratoModule',
            },
            {
                'path': 'invite',
                'loadChildren': '.\/pages\/invite\/invite.module#InviteModule',
            },
            {
                'path': 'listprofile',
                'loadChildren': '.\/pages\/listprofile\/listprofile.module#ListProfileModule',
            },
            {
                'path': 'marketplace-pipooh',
                'loadChildren': '.\/pages\/marketplace-pipooh\/marketplace-pipooh.module#MarketplacePipoohModule',
            },
            {
                'path': 'resgate',
                'loadChildren': '.\/pages\/resgate\/resgate.module#ResgateModule',
            },
            {
                'path': 'resgate-novo',
                'loadChildren': '.\/pages\/resgate-novo\/resgate-novo.module#ResgateNovoModule',
            },
            {
                'path': 'resgate-garantido',
                'loadChildren': '.\/pages\/resgate-garantido\/resgate-garantido.module#ResgateGarantidoModule',
            },
            {
                'path': 'resgate-rh',
                'loadChildren': '.\/pages\/resgate-rh\/resgate-rh.module#ResgateRhModule',
            },
            {
                'path': 'rifa',
                'loadChildren': '.\/pages\/rifa\/rifa.module#RifaModule',
            },
            {
                'path': 'presentes',
                'loadChildren': '.\/pages\/presentes\/presentes.module#PresentesModule',
            },
            {
                'path': 'produtos-bloqueados',
                'loadChildren': '.\/pages\/produtos-bloqueados\/produtos-bloqueados.module#ProdutosBloqueadosModule',
            },
            {
                'path': 'endereco',
                'loadChildren': '.\/pages\/endereco\/endereco.module#EnderecoModule',
            },
            {
                'path': 'estabelecimento',
                'loadChildren': '.\/pages\/estabelecimento\/estabelecimento.module#EstabelecimentoModule',
            },
            {
                'path': 'gift-estabelecimento',
                'loadChildren': '.\/pages\/gift-estabelecimento\/gift-estabelecimento.module#GiftEstabelecimentoModule',
            },
            {
                'path': 'pontos',
                'loadChildren': '.\/pages\/pontos\/pontos.module#PontosModule',
            },
            {
                'path': 'transferencia',
                'loadChildren': '.\/pages\/transferencia\/transferencia.module#TransferenciaModule',
            },
            {
                'path': 'organizador',
                'loadChildren': '.\/pages\/organizador\/organizador.module#OrganizadorModule',
            },
            {
                'path': 'perfil-organizador',
                'loadChildren': '.\/pages\/perfil-organizador\/perfil-organizador.module#PerfilOrganizadorModule',
            },
            {
                'path': 'marketplace',
                'loadChildren': '.\/pages\/marketplace\/marketplace.module#MarketplaceModule',
            },
            {
                'path': 'marketplace-anuncio',
                'loadChildren': '.\/pages\/marketplace-anuncio\/marketplace-anuncio.module#MarketplaceAnuncioModule',
              
            },
            {
                'path': 'loja',
                'loadChildren': '.\/pages\/loja-integrada\/loja-integrada.module#LojaIntegradaModule',
            },
            {
                'path': '',
                'redirectTo': 'admin',
                'pathMatch': 'full',
            },
        ],
    },
    {
        'path': '**',
        'redirectTo': 'admin',
        'pathMatch': 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule { }