import { Injectable } from '@angular/core';
import { environment, environmentAspNet } from '../../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ResgateService {
  private carrinho = new BehaviorSubject<string>(`0`);

  private URL = `${environment.api}`;
  private URLAspNet = `${environmentAspNet.api}`;
  userToken: any = localStorage.getItem('pipoohUser');

  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

  authFormData(formData) {
    this.userToken = localStorage.getItem('pipoohUser');
    formData.token = this.userToken;
    return formData;
  }
  postData(url, formData): Promise<any> {
    return this.http.post(url, formData).toPromise().then(response => {
      var aux: any = response;
      if (aux.success == false) {
        this.toastr.error(aux.error, "Erro de solicitação");
        return aux;
      }
      else {
        return aux;
      }
    });

  }

  getExtrato(formData) {
    return this.postData(`${this.URL}resgate/saldo`, formData);
  }
  listResgate(formData) {
    return this.postData(`${this.URL}resgate/list`, formData);
  }
  listSugestao(formData) {
    return this.postData(`${this.URL}resgate/select_sugestao_all`, formData);
  }
  insertSugestao(formData) {
    return this.postData(`${this.URL}resgate/insert_sugestao`, formData);
  }
  resgateDetails(formData) {
    return this.postData(`${this.URL}resgate/details`, formData);
  }
  listCarrinho(formData) {
    return this.postData(`${this.URLAspNet}resgate/app/carrinho/pendente`, this.authFormData(formData));
  }
  updateProdutoCarrinho(formData) {
    return this.postData(`${this.URLAspNet}resgate/app/update/produto/carrinho`, this.authFormData(formData));
  }
  carrinhoSize(formData) {
    return this.postData(`${this.URLAspNet}resgate/app/carrinho/select/size`, this.authFormData(formData));
  }
  inserirProduct(formData) {
    return this.postData(`${this.URL}resgate/carrinho/inserir`, formData);
  }
  deleteProduct(formData) {
    return this.postData(`${this.URL}resgate/carrinho/delete`, formData);
  }
  inserirResgate(formData) {
    return this.postData(`${this.URL}resgate/insert`, formData);
  }
  inserirResgatePontos(formData) {
    return this.postData(`${this.URLAspNet}resgate/app/insert`, this.authFormData(formData));
  }
  updateStatusCancelado(formData) {
    return this.postData(`${this.URL}resgate/update_cancelado`, formData);
  }

  cancelarResgate(formData) {
    return this.postData(`${this.URLAspNet}resgate/app/update/cancelado`, this.authFormData(formData));
  }

  inserirLogBusca(formData) {
    return this.postData(`${this.URLAspNet}resgate/app/insert/logBusca`, formData);
  }
  selecionarResgateComCupom(formData) {
    return this.postData(`${this.URLAspNet}resgate/app/select/cupom/usuario`, this.authFormData(formData));
  }

  obterCarrinho() {
    // this.carrinhoSize({}).then(data => {
    //   this.carrinho = data.payload[0].quantidadeProdutos;
      // console.log("valor atualizado", this.carrinho)
    //   return this.carrinho;
    // })
    return this.carrinho;
  }
  alterarCarrinho(valor : string) {
    // this.carrinhoSize({}).then(data => {
    //   var valor = data.payload[0].quantidadeProdutos;
    //   this.carrinho.next(valor);
    // })
    this.carrinho.next(valor);
  }
}
