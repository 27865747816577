import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.routing';
import { AuthComponent } from './auth.component';
import { LogoutComponent } from './logout/logout.component';
import { AlertComponent } from './_directives/alert.component';
import { AuthGuard } from './_guards/auth.guard';
import { AlertService } from './_services/alert.service';
import { AuthenticationService } from './_services/authentication.service';
import { UserService } from './_services/user.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { RecaptchaModule } from "ng-recaptcha";




@NgModule({
    declarations: [
        AuthComponent,
        AlertComponent,
        LogoutComponent,
    ],
    imports: [
        NgxMaskModule.forRoot(),
        CommonModule,
        FormsModule,
        HttpClientModule,
        AuthRoutingModule,
        MatCheckboxModule,
        MatTabsModule,
        MatCardModule,
        RecaptchaModule
    ],
    exports: [
        MatTabsModule,
        MatCardModule,
    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        UserService       
    ],
    entryComponents: [AlertComponent],
})

export class AuthModule {
}