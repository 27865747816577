import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AuthService } from '../../../admin/_services/auth.service';

declare let mLayout: any;

@Component({
    selector: "app-aside-nav",
    templateUrl: "./aside-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit {

    infoUser: any = [];
    constructor(private _authService: AuthService,) {

    }
    ngOnInit() {
    }
    getInfoUser() {
        this._authService.getUser().subscribe(data => {
            if (data) {
                this.infoUser = data;
            }
        });
    }
    ngAfterViewInit() {

        // mLayout.initAside();


    }

}