import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { AuthService } from '../../admin/_services/auth.service';
import { UserService } from '../../admin/_services/user.service';
import { Router } from "@angular/router";
import { AlertService } from ".";

@Injectable()
export class AuthenticationService {

    user: any = [];
    constructor(private _router: Router, private authService: AuthService, private userService: UserService, private _alertService: AlertService, private http: HttpClient) {
    }

    async login(email: string, password: string) {
        var obj = { "email": email, "pass": password };
        await this.authService.login(obj).then(data => {
            this.user = data;
        }).catch(function (e) {
            return false;
        });
        if (this.user.payload) {
            if (this.user.payload.length == 0) {
                return false;
            }
            return await localStorage.setItem('pipoohUser', this.user.payload[0].token);
        }
        else {
            return false;
        }

    }

    async novaRef(refRemarketing: string, token: string) {
        var obj = { "refRemarketing": refRemarketing, "token": token };
        await this.authService.novaRef(obj);

    }
    async codigoCheck(codigo: string) {
        return await this.authService.codigoCheck({ "codigo": codigo }).then(data => {
            var aux: any = data;
            return aux;
        });
    }
    async signUp(name: string, sexo: string, email: string, cpf: string, password: string, passwordConfirm: string, celular: string, ultimaMenstruacao: string, ref: string, codigo: string, franquiaID: string, tipo: string, nomeCrianca: string, sexoCrianca: string, dataNascimentoCrianca: string, captacao: string) {
        if (password != passwordConfirm) { return false; }

        var obj = { "name": name, "email": email, "cpf": cpf, "password": password, "sexo": sexo, "celular": celular, "ultimaMenstruacao": ultimaMenstruacao, "ref": ref, "codigo": codigo, "fkFranquia": franquiaID, "tipo": tipo, "nomeCrianca": nomeCrianca, "sexoCrianca": sexoCrianca, "dataNascimentoCrianca": dataNascimentoCrianca, "captacao": captacao };
        await this.userService.userInsert(obj).then(data => {
            this.user = data;
        }).catch(function (e) {
            return false;
        });
        if (this.user.payload) {
            if (this.user.payload.length == 0) {
                return false;
            }
            if (String(this.user.payload).substring(0, 15) == "SQLSTATE[23000]") { return "E-mail cadastrado"; }
            else {
                // localStorage.setItem('pipoohNewHome', '1');
                localStorage.setItem('pipoohNewResgate', '1');
                localStorage.setItem('pipoohNewConvite', '1');
                localStorage.setItem('pipoohNewRifa', '1');
                localStorage.setItem('pipoohNewProfile', '1');
                localStorage.setItem('pipoohNewProfileCha', '1');
                localStorage.setItem('pipoohNewListProfile', '1');
                return await localStorage.setItem('pipoohUser', this.user.payload[0].token);
            }
        }
        else {
            return false;
        }
    }

    async forgotPassword(email: string) {
        var obj = { "email": email };
        await this.authService.forgotPassword(obj).then(data => {
            this.user = data;
        }).catch(function (e) {
            console.log(e);
            return false;
        });
        if (this.user.payload) {
            if (this.user.payload.length == 0) {
                return false;
            }
            return await localStorage.setItem('pipoohUser', this.user.token);
        }
        else {
            return false;
        }

    }

    async rdConversao(name: string, email: string, celular: string, ultimaMenstruacao: string, ref: string, tipo: string, nomeCrianca: string, sexoCrianca: string, dataNascimentoCrianca: string, captacao: string) {
        var obj = { "name": name, "email": email, "celular": celular, "ultimaMenstruacao": ultimaMenstruacao, "ref": ref, "tipo": tipo, "nomeCrianca": nomeCrianca, "sexoCrianca": sexoCrianca, "dataNascimentoCrianca": dataNascimentoCrianca, "captacao": captacao };
        this.authService.rdConversao(obj).then(data => {
            this.user = data;
        }).catch(function (e) {
            console.log(e);
            return false;
        });
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('pipoohUser');
    }
}