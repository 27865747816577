import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root'})
export class FranquiaService {

    private URL = `${environment.api}`;

  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

    postData(url, formData): Promise<any>
    {
        return this.http.post(url, formData).toPromise().then(response =>
        {
            var aux: any = response;
            if (aux.success == false)
            {
                this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else 
            {
                return aux;
            }
        });

    }

  getFranquia(formData) {
    return this.postData(`${this.URL}franquia_all`, formData);
  }
}
