import { Injectable } from '@angular/core';
import { environment, environmentAspNet, environment_v3 } from '../../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PedidoService {

  private URL = `${environment.api}`;
  private URLAspNet = `${environmentAspNet.api}`;
  private URLAPIV3 = `${environment_v3.api}`;
  userToken: any = localStorage.getItem('pipoohUser');

  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

  authFormData(formData) {
    this.userToken = localStorage.getItem('pipoohUser');
    formData.token = this.userToken;
    return formData;
  }
  postData(url, formData): Promise<any> {
    return this.http.post(url, formData).toPromise().then(response => {
      var aux: any = response;
      if (aux.success == false) {
        this.toastr.error(aux.error, "Erro de solicitação");
        return aux;
      }
      else {
        return aux;
      }
    });

  }
  pedidosSelect(formData) {
    return this.postData(`${this.URL}pedidos/user/select`, formData);
  }
  pedidosPagos(formData) {
    return this.postData(`${this.URL}pedidos/pagos`, formData);
  }

  pedidosInsert(formData) {
    return this.postData(`${this.URL}pedidos/insert`, formData);
  }
  buscarPedidoUsuario(formData) {
    return this.postData(`${this.URLAspNet}pedido/organizador/select/usuario`, this.authFormData(formData));
  }
  pedidosInsertCredito(formData) {
    return this.postData(`${this.URLAspNet}pedido/app/insert/credito`, this.authFormData(formData));
  }
  pedidosInsertPagamento(formData) {
    return this.postData(`${this.URLAspNet}pedido/app/insert/pagamento`, this.authFormData(formData));
  }
  pedidosUpdateGiftCard(formData) {
    return this.postData(`${this.URLAspNet}pedido/app/update/giftcard`, this.authFormData(formData));
  }
  pedidosSelectEvento(formData) {
    return this.postData(`${this.URLAspNet}pedido/app/select/evento`, this.authFormData(formData));
  }
  pedidosSelectPresente(formData) {
    return this.postData(`${this.URLAspNet}pedido/app/select/presentes`, this.authFormData(formData));
  }
  inserirPedidoAssinatura(formData) {
    return this.postData(`${this.URLAspNet}pedido/landing/insert/assinatura`, formData);
  }

  buscarEventoServico(idEvento, presente): Observable<any> {
    console.log("presente", presente)
    return this.http.get<any>(`${this.URLAPIV3}api/Pedido/evento/servico?idEvento=${idEvento}&presente=${presente}&token=${this.userToken}` );
  }
}
