import { Injectable } from '@angular/core';
import { environment, environmentAspNet } from '../../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable  } from 'rxjs';
@Injectable({ providedIn: 'root' })

export class AuthService {
  private URL = `${environment.api}`;
  private URLAspNet = `${environmentAspNet.api}`;
  userToken: any = localStorage.getItem('pipoohUser');
  private userData = new BehaviorSubject<any>(undefined)
  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

  postData(url, formData): Promise<any> {
    return this.http.post(url, formData).toPromise().then(response => {
      var aux: any = response;
      if (aux.success == false) {
        this.toastr.error(aux.error, "Erro de solicitação");
        return aux;
      }
      else {
        return aux;
      }
    });

  }
  postDataToken(url, formData): Promise<any> {
    return this.http.post(url, formData).toPromise().then(response => {
      var aux: any = response;
      if (aux.success == false) {
        this.toastr.error("Sessão expirada", "Logue novamente");
        return aux;
      }
      else {
        return aux;
      }
    });

  }
  setUser(user): void {
    this.userData.next(user);
  }

  getUser(): Observable<any> {
    return this.userData;
  }
  authFormData(formData) {
    this.userToken = localStorage.getItem('pipoohUser');
    formData.token = this.userToken;
    return formData;
}

  login(formData) {
    return this.postData(`${this.URLAspNet}auth/app/login`, formData);
  }
  signUp(formData) {
    return this.postData(`${this.URL}auth/signUp`, formData);
  }
  getToken(formData) {
    return this.postDataToken(`${this.URLAspNet}auth/app/token`, formData);
  }
  forgotPassword(formData) {
    return this.postData(`${this.URL}auth/forgotPassword`, formData);
  }
  codigoCheck(formData) {
    return this.postData(`${this.URL}auth/codigoCheck`, formData);
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('pipoohUser');
  }
  novaRef(formData) {
    return this.postData(`${this.URLAspNet}tracking/remarketing`, formData);

  }
  rdConversao(formData){
    return this.postData(`${this.URLAspNet}rdstation/conversao/cadastro`, formData);

  }
  inserirCodigoTransferencia(formData){
    return this.postData(`${this.URLAspNet}auth/app/update/codigo/transferencia`, this.authFormData(formData));
  }
  validarCodigo(formData){
    return this.postData(`${this.URLAspNet}auth/app/select/codigo`, formData);
  }
  autenticarEmail(formData){
    return this.postData(`${this.URLAspNet}auth/app/select/codigo/email`, formData);
  }
  //   logout() {
  //     return this.http.get(`${this.URL}logout`);
  //   }
  //   loged() {
  //     return this.http.get(`${this.URL}loged`);
  //   }
}
