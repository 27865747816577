import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Helpers } from '../../../helpers';
import { AuthService } from '../../../admin/_services/auth.service';

declare let mLayout: any;
@Component({
    selector: "app-menu-mobile",
    templateUrl: "./menu-mobile.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class MenuMobileComponent implements OnInit {

    infoUser: any = [];

    constructor(private _authService: AuthService) {

    }
    ngOnInit() {
    }
    getInfoUser() {
        let data = localStorage.getItem('pipoohUser');
        this._authService.getToken({ "token": data }).then(data => {
            this.infoUser = data;
            this.infoUser = this.infoUser.payload[0];

        }).catch(e => { console.log(e); });
    }

}