import { Injectable, } from '@angular/core';
import { environment, environmentAspNet } from '../../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HelperService {

  private URL = `${environment.api}`;
  private URLAspNet = `${environmentAspNet.api}`;
  userToken: any = localStorage.getItem('pipoohUser');
  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

  postData(url, formData): Promise<any> {
    return this.http.post(url, formData).toPromise().then(response => {
      var aux: any = response;
      if (aux.success == false) {
        this.toastr.error(aux.error, "Erro de solicitação");
        return aux;
      }
      else {
        return aux;
      }
    });

  }

  freteCorreios(formData) {
    // console.log("formData Correio: ",formData);
    return this.http.post(`${this.URL}helper/frete`, formData).toPromise();
  }
 
  calculafreteCorreios(formData){
    return this.postData(`${this.URLAspNet}helper/app/consultaFrete`, formData);
  }
  getLocationKMs(formData) {
    //return this.http.get(`https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&origins=`+ obj.origem +`&destinations=`+ obj.destino +`&key=AIzaSyA8ScidnV4Lhyt5-4Ko-u3VFrtodorAMEo`);
    return this.http.post(`${this.URL}helper/googlemaps`, formData).toPromise();
  }
  sendPicture(formData) {

    // let headers = new HttpHeaders({
    //   'Content-Type': 'multipart/form-data',
    //   'Accept': 'application/json'
    // });
    // let options = {
    //     headers: headers
    // }
    return this.http.post(/*`${this.URL}*/`https://apiAzure.pipooh.com.br/helper/upload_image`, formData).toPromise();
  }
  divulgacaoEmail(formData) {
    return this.postData(`${this.URLAspNet}helper/app/organizador/divulgacao/email`, formData);
  }
}
